import { createNextState, nanoid } from '@reduxjs/toolkit';
import { AppConfig } from '../config';
import AlertMessage from 'interfaces/AlertMessage';
import Alerts from 'state/actions/alerts/Alerts';
import Prompt from 'state/actions/prompts/Prompt';
import { PromptAction } from 'interfaces/prompt-interfaces';
import RecordCache from 'state/actions/cache/RecordCache';
import cacheAlertMessages from 'constants/alerts/cacheAlerts';
import { UserRecordCacheStatus } from 'interfaces/UserRecordSet';

interface AlertsAndPromptsState {
  alerts: AlertMessage[];
  prompts: any[];
}

const initialState: AlertsAndPromptsState = {
  alerts: [],
  prompts: []
};

const filterDuplicates = (key: keyof AlertMessage, matchValue: any, state: AlertMessage[]): any[] =>
  state.filter((entry) => entry[key] !== matchValue);

const addAlert = (state: AlertMessage[], alert: AlertMessage): AlertMessage[] => [...state, { ...alert, id: nanoid() }];
const addPrompt = (state: PromptAction[], prompt: PromptAction): PromptAction[] => [
  ...state,
  { ...prompt, id: nanoid() }
];

export function createAlertsAndPromptsReducer(
  configuration: AppConfig
): (AlertsAndPromptsState, AnyAction) => AlertsAndPromptsState {
  return (state: AlertsAndPromptsState = initialState, action) => {
    return createNextState(state, (draftState) => {
      if (Alerts.create.match(action)) {
        const newAlertIsDuplicate = state.alerts.some(
          (item) => action.payload.content === item.content && action.payload.severity === item.severity
        );
        if (!newAlertIsDuplicate) {
          draftState.alerts = [...state.alerts, { ...action.payload, id: nanoid() }];
        }
      } else if (Alerts.deleteOne.match(action)) {
        draftState.alerts = filterDuplicates('id', action.payload.id, state.alerts);
      } else if (Alerts.deleteAll.match(action)) {
        draftState.alerts = [];
      } else if (Prompt.closeOne.match(action)) {
        draftState.prompts = filterDuplicates('id', action.payload.id, state.prompts);
        draftState.prompts = state.prompts.filter((prompt) => prompt.id !== action.payload.id);
      } else if (Prompt.closeAll.match(action)) {
        draftState.prompts = [];
      } else if (RegExp(Prompt.NEW_PROMPT).exec(action.type)) {
        const newPrompt: PromptAction = action.payload;
        draftState.prompts = addPrompt(state.prompts, newPrompt);
      } else if (
        RecordCache.requestCaching.fulfilled.match(action) &&
        action.payload.status === UserRecordCacheStatus.CACHED
      ) {
        draftState.alerts = addAlert(state.alerts, cacheAlertMessages.recordsetCacheSuccess);
      } else if (RecordCache.requestCaching.rejected.match(action)) {
        draftState.alerts = addAlert(state.alerts, cacheAlertMessages.recordsetCacheFailed);
      } else if (RecordCache.deleteCache.rejected.match(action)) {
        draftState.alerts = addAlert(state.alerts, cacheAlertMessages.recordsetDeleteCacheFailed);
      } else if (RecordCache.stopDownload.fulfilled.match(action)) {
        draftState.alerts = addAlert(state.alerts, cacheAlertMessages.recordSetDownloadStoppedEarly);
      } else if (RecordCache.deleteCache.fulfilled.match(action)) {
        draftState.alerts = addAlert(state.alerts, cacheAlertMessages.recordsetDeleteCacheSuccess);
      }
    });
  };
}
